import "../scss/main.scss";

import $ from "jquery";
window.$ = window.jQuery = $;

require("fslightbox");

require("jquery-ui/ui/widgets/datepicker.js");
require("jquery-ui/ui/i18n/datepicker-nl.js");

import initCookieConsent from "./cookieconsent.js";

import Bloodhound from "typeahead.js";

import "slick-carousel";

import { Event, Element, Collapse, Modal, Popover, Tooltip } from "bootstrap-sass";

var Mustache = require("mustache");

import dayjs from "dayjs";
import "dayjs/locale/nl";

const ENABLE_SST = window.ENABLE_SST = true;

dayjs.locale("nl");

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

function validate_element(el) {
  // validates a form element.
  let $el = $(el);

  if (!el.validity.valid && !$el.hasClass("error")) {
    $el.addClass("error");
  } else if ($el.hasClass("error")) {
    $el.removeClass("error");
  }
}

function isDateSupported() {
  var input = document.createElement("input"),
    value = "a";

  input.setAttribute("type", "date");
  input.setAttribute("value", value);

  return input.value !== value;
}

function isMobileOS() {
  return /android|i(pad|phone|pod)+/i.test(navigator.userAgent);
}

window.dataLayer = window.dataLayer || [];
if (ENABLE_SST) {
   window.dataLayer2 = window.dataLayer2 || window.dataLayer;
}

function gtag() {
  dataLayer.push(arguments);

  if (ENABLE_SST) {
    dataLayer2.push(arguments);
  }
}


$(function () {
  const date_is_supported = isDateSupported();
  const is_mobile_os = isMobileOS();

  console.info({
    date_is_supported: date_is_supported,
    is_mobile_os: is_mobile_os,
  });


  // initialize cookie consent
  initCookieConsent();


  // initialize tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // initialize popover
  $('[data-toggle="popover"]').popover();
  $(".popover-item").popover({
    placement: "top",
    html: "true",
    container: "body",
    trigger: "hover",
  });

  $('input[readonly], select[readonly]').on('mousedown', (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    return false;
  });

  // initialize element validation at input for all form elements
  $("form").each((idx, form) => {
    Array.from(form.elements).forEach((el, idx) => {
      $(el).on("input", (e) => {
        validate_element(e.target);
      });
    });
  });

  // initialize form validation on submit
  $("form").on("submit", function (e) {
    let form = e.target;
    if (!form.checkValidity()) {
      console.info("form is not valid");

      e.preventDefault();
      e.stopPropagation();

      // check which elements are not valid
      Array.from(form.elements).forEach((el, idx) => {
        validate_element(el);
      });

      return false;
    }
  });

  $('input[type="date"], input.js-datepicker').each(function (idx, elm) {
    var $elm = $(elm),
      min = $elm.attr("min") ? dayjs($elm.attr("min"), "YYYY-MM-DD") : false,
      max = $elm.attr("max") ? dayjs($elm.attr("max"), "YYYY-MM-DD") : false,
      now = $elm.val() ? dayjs($elm.val(), "DD-MM-YYYY") : dayjs({}),
      is_birthdate = /birthdate/.test($elm.attr("name")) ? true : false;

    if ($elm.attr('readonly')) {
      return;
    }

    var config = {
      dateFormat: "dd-mm-yy",
      defaultDate: now.toDate(),
      minDate: min ? min.toDate() : now.toDate(),
      maxDate: max ? max.toDate() : "+1y",
      changeMonth: is_birthdate,
      changeYear: is_birthdate,
      showWeek: true,
      firstDate: 1,
    };

    if (is_birthdate) {
      config.maxDate = new Date();
      config.minDate = new Date(1920, 0, 1);
      config.yearRange = "1920:c";
    }

    if (!date_is_supported || (date_is_supported && !is_mobile_os)) {
      $elm.datepicker(config);
    }
  });

  $(".lister-item-carousel").each(function (el) {
    $(this).slick({
      lazyLoad: "ondemand",
      slidesToScroll: 1,
      prevArrow: gt.slick_arrow_prev,
      nextArrow: gt.slick_arrow_next,
    });
  });

  $("#detail-carousel").each(function (el) {
    $(this).slick({
      lazyLoad: "ondemand",
      slidesToScroll: 1,
      prevArrow: gt.slick_arrow_prev,
      nextArrow: gt.slick_arrow_next,
    });
  });

  $(".calendar-wrapper").each(function () {
    $(this).slick({
      lazyLoad: "ondemand",
      slidesToScroll: 1,
      infinite: false,
      prevArrow: gt.slick_circle_arrow_prev,
      nextArrow: gt.slick_circle_arrow_next,
    });
  });


  if ($('#cta-mobile').length) {
    const cta_mobile = $('#cta-mobile');
    const first_package = $('section#section-packages div.package')
    cta_mobile.on('click', (ev) => {
      ev.preventDefault();
      document.documentElement.scrollTop = first_package.offset().top - 100;
    });
  }



  //open and close tab menu for mobile
  $(".nav-tabs-dropdown")
    .on("click", "li:not('.active') a", function (event) {
      $(this).closest("ul").removeClass("open");
      const el = document.querySelector('ul.nav-tabs[role="tablist"]');
      const tab_content = document.querySelector('div.tab-content');
      console.info(el, tab_content.scrollTop, $('div.tab-content').offset());
      console.info(document.documentElement.scrollTop);
      document.documentElement.scrollTop = $('div.tab-content').offset().top - 110;
    })
    .on("click", "li.active a", function (event) {
      $(this).closest("ul").toggleClass("open");
    });


  // make cta button sticky on mobile
  // if ($(".nav-tabs-dropdown").length) {
  //   stickyElement(".detail-tabs", ".nav-tabs-dropdown", false);
  // }

  $(".modal").on("shown.bs.modal", function () {
    $(".calendar-wrapper").slick("setPosition");
    $(window).trigger("resize");
  });

  $('a[data-toggle="tab"]').on("shown.bs.tab", function () {
    $(".lister-item-carousel").slick("setPosition");
    $(window).trigger("resize");
  });

  $(".main-img-header .score").on("click", function () {
    var tab = $('a[href="#klantervaringen"]');
    tab.tab("show");
    $("html, body").animate(
      {
        scrollTop: tab.offset().top - 100,
      },
      2000
    );
  });

  // make in-page navigation sticky
  if ($("#page-nav-menu").length) {
    var previousScroll = 0,
      headerOrgOffset = $("#page-nav-menu").offset().top;
    $(window).scroll(function () {
      var currentScroll = $(this).scrollTop();

      if (currentScroll > headerOrgOffset) {
        $("#page-nav-menu").addClass("navbar-fixed-top");
      } else {
        $("#page-nav-menu").removeClass("navbar-fixed-top");
      }
      previousScroll = currentScroll;
    });
  }

  // create hash links animation
  $(".hash-link").on("click", function (e) {
    e.preventDefault();
    var section = $(this).attr("href");
    $("html, body").animate(
      {
        scrollTop: $(section).offset().top - 100,
      },
      2000
    );
  });

  // load more course in courses list
  $("#moreCourses").on("click", function (e) {
    e.preventDefault();
    $(this).remove();
    $(".lister-item-golfbaan.toggle-state").removeClass("toggle-state");
    $(".lister-item-golfbaan.hidden").each(function () {
      setTimeout($(this).removeClass("hidden"), 300);
    });
  });

  // load more packages in lister item
  $(".more-packages").on("click", function (e) {
    $(this).parent().parent().toggleClass("show-all");
  });

  // calendar
  $(".tsprices-calendar-month").on("click", function (ev) {
    var $target = $(ev.target);
    if (!$target || !$target.hasClass("price")) {
      console.debug("no price selected");
      return;
    }

    var $parent = $($target.parent());
    if (!$parent) {
      console.debug("could not get parent or price is not available");
      return;
    }

    var $container = $parent.parents(".calendar-container"),
      $wishform = $container.parent().find(".wishform-block");

    if ($parent.hasClass("not-available")) {
      $(".calendar-cart").hide();
      $wishform.show();
      return;
    }

    $(".calendar-cart").show();
    $wishform.hide();

    var package_id = $container.data("package"),
      book_link = $container.data("booklink"),
      day_cell = $parent.parent(),
      selected_date = $parent.data("current_date");

    var $package_details_container = $("#package-details-" + package_id);
    $package_details_container.html("Bezig met berekenen.");
    $package_details_container.parents(".panel").find(".panel-footer").html("");

    $.ajax(window.prices_api_link, {
      dataType: "json",
      contentType: "application/json",
      method: "post",
      data: JSON.stringify({
        package_id: package_id,
        selected_date: selected_date,
      }),
      beforeSend: function () {
        $(".calendar-day-event").each(function () {
          $(this).removeClass("active");
        });
        $container.addClass("loading");
        day_cell.addClass("active");
      },
      success: function (xmlHttpResponse) {
        // parse date to locale date string
        xmlHttpResponse.locale_date = dayjs(
          xmlHttpResponse.date,
          "YYYY-MM-DD"
        ).format("DD MMMM");
        xmlHttpResponse.book_link = book_link;

        var $package_details_container = $(
          "#package-details-" + xmlHttpResponse.package_id
        );

        var package_details_content = Mustache.render(
          $("#package_details_template").html(),
          xmlHttpResponse
        );
        $package_details_container.html(package_details_content);

        var package_footer_content = Mustache.render(
          $("#package_footer_template").html(),
          xmlHttpResponse
        );
        $package_details_container
          .parents(".panel")
          .find(".panel-footer")
          .html(package_footer_content);
      },
      complete: function () {
        $container.removeClass("loading");
        $(".modal.in").animate(
          {
            scrollTop: 1000,
          },
          2000
        );
      },
    });
  });

  const ww =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const wh =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  function check_show_filters() {
    if (ww >= 786 && !$("#filterCollapse").hasClass("in")) {
      $("#filterCollapse").addClass("in");
    } else if (ww < 786 && $("#filterCollapse").hasClass("in")) {
      $("#filterCollapse").removeClass("in");
    }
  }

  // $(window).on('resize', check_show_filters);
  check_show_filters();

  $("#zoek-widget")
    .find('select, input[type="checkbox"]')
    .on("change", function (ev) {
      var $this = $(this),
        $location = $('select[name="location"]'),
        $country = $('select[name="country"]'),
        $region = $('select[name="region"]'),
        $subregion = $('select[name="subregion"]');

      if ($this.attr("name") == "country") {
        // reset value for region and subregion before submit
        $region.val(false);
        $subregion.val(false);
      }

      if ($this.attr("name") == "region") {
        // reset subregion before submit
        $subregion.val(false);
      }

      $("#zoek-widget").submit();
    });

  // make receipt be fixed
  // $("#reisoverzicht").affix({
  //   // target: '.aanvraag-container',
  //   offset: {
  //     top: 0,
  //     // bottom: function (el) {
  //     //   var that = $(el);

  //     //   // window.setTimeout(function() {
  //     //   //   that.css({'position': 'absolute'});
  //     //   // }, 200);

  //     //   return 100;
  //     // }
  //   },
  // });

  function stickyElement(section, element, showTop) {
    var previousScroll = 0;
    var sectionOffsetTop = $(section).offset().top - $(section).height();
    var sectionOffsetBottom = $(section).offset().top;

    $(window).scroll(function () {
      var currentScroll = $(this).scrollTop();
      if (
        (currentScroll < sectionOffsetTop && showTop) ||
        currentScroll > sectionOffsetBottom
      ) {
        $(element).addClass("sticky");
      } else {
        $(element).removeClass("sticky");
      }
      previousScroll = currentScroll;
    });
  }

  $(".gallery-thumbnail > span").on("click", function (e) {
    var $this = $(this);
    $("#detail-carousel").slick("slickGoTo", $this.data("index"));
  });

  $("a.phone-number-link").on("click", function (e) {
    let loc = "not-set";

    for (let check of [
      "desktop-header",
      "mobile-header",
      "contact-page",
      "mobile-footer",
    ]) {
      if ($(this).hasClass(check)) {
        loc = check;
        break;
      }
    }

    try {
      console.info("gtag phone number clicked", loc);
      gtag("event", "phone_number_clicked", {
        location: loc,
      });
    } catch (err) {
      console.error(err);
    }
  });

  // lazy loading dom elements
  var lazyloadDomElements = $("img.lazy, iframe.lazy");

  if ("IntersectionObserver" in window) {
    console.info("using IntersectionObserver");

    var domObserver = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            var domElement = entry.target;

            if (domElement.src && !domElement.dataset.src) {
              domElement.classList.remove("lazy");
              domElement.classList.add(
                "lazy-load-error",
                "lazy-load-data-src-not-set"
              );
              return;
            }

            console.info("lazy loading domElement " + domElement.dataset.src);

            domElement.src = domElement.dataset.src;
            domElement.classList.remove("lazy");
            domObserver.unobserve(domElement);
          }
        });
      },
      { rootMargin: "20px" }
    );

    lazyloadDomElements.each(function (idx, image) {
      domObserver.observe(image);
    });
  } else {
    var lazyloadThrottleTimeout;

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadDomElements.each(function (idx, domElement) {
          if (domElement.src && !domElement.dataset.src) {
            domElement.classList.remove("lazy");
            domElement.classList.add(
              "lazy-load-error",
              "lazy-load-data-src-not-set"
            );
            return;
          }

          if (domElement.offsetTop < window.innerHeight + scrollTop) {
            domElement.src = domElement.dataset.src;
            domElement.classList.remove("lazy");
          }
        });

        if (lazyloadDomElements.length == 0) {
          $(document).off("scroll", lazyload);
          $(window).off("resize, orientationChange", lazyload);
        }
      }, 20);
    }

    $(document).on("scroll", lazyload);
    $(window).on("resize, orientationChange", lazyload);
  }

  // location finder
  function filter_select_options(domElement, parentValue) {
    // hide all options options with a parent value
    $(domElement).find("option[data-parent]").hide();

    // show only options with the selected parent value
    $(domElement)
      .find("option[data-parent=" + parentValue + "]")
      .show();
  }

  const $location_finders = $("div.location-finder");
  if ($location_finders.length) {
    // we have location finders on the current page
    let $countries = $location_finders.find("select.countries");

    // initialy filter all regions based on the selected country
    filter_select_options(
      $location_finders.find("select.regions"),
      $countries.val()
    );

    $countries.on("change", function (ev) {
      filter_select_options(
        $location_finders.find("select.regions"),
        $(this).val()
      );
    });
  }

  if (/localhost/.test(document.location.host)) {
    console.info("running in dev mode");
    $('div.viewport-size-helper').removeClass('hidden');
  };


  function onCaptchaFormSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    var $form = $(this),
      form_name = $form.attr("name");

    if (grecaptcha) {
      grecaptcha
        .execute(window.recaptcha, { action: "submit" })
        .then(function (token) {
          // console.info('got token: ', token);
          var $security_input = $("input#" + form_name + "_security");
          if ($security_input.length >= 1) {
            $security_input.val(token);
          }

          // $form.off("submit", onCaptchaFormSubmit);
          // return ev.target.submit();

          if (ev.target && ev.target.checkValidity()) {
            $form.off("submit", onCaptchaFormSubmit);
            return ev.target.submit();
          }

          return false;
        });

      return false;
    }
  }

  function initialize_forms(grecaptcha) {
    $("form").each(function (idx, form) {
      const $form = $(form),
        form_name = $form.attr("name");

      if (!form_name) {
        console.info('form has no name');
        return;
      }

      console.info('Found form with name: ', form_name);

      $form.on("submit", onCaptchaFormSubmit);
    });
  }


  // dynamicly add google recaptcha
  var script = document.createElement("script");
  document.body.appendChild(script);
  script.onload = function (e) {
    // console.info("recaptcha loaded");
    grecaptcha.ready(function () {
      // console.info("recaptcha ready", window.recaptcha, grecaptcha);

      initialize_forms(grecaptcha);

    });
  };

  script.src =
    "https://www.google.com/recaptcha/api.js?render=" + window.recaptcha;
});
